import React, { useState, useEffect, useContext} from 'react';
import Layout from '@components/structure/Layout';
import { alpha, Tab, Tabs, styled, Grid, Box } from '@mui/material';
import SessionInfoCard from '@mui-components/sessionInfoCard.jsx';
import CtaButton from '@mui-components/ctaButton.jsx';
import OnDemandVideo from '@components/structure/OnDemandVideo.jsx';
import Filters from '@mui-components/filters.jsx';
import Seo from '@components/utility/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import { SlowReveal } from '../../components/utility/Animations';
import { data } from '../../data/ihBranded'
import { AppContext } from '@context'
import { Api } from '@components/utility/Api';
import { navigate } from 'gatsby';
import Preloader from '@components/structure/Preloader';


const IhbrandedHomePage = ({ location }) => {
	const GROUP = "branded";
	const CONDITION = 'brandedih';

	const {selectedWebinars, setSelectedWebinars} = useContext(AppContext);


	const StyledTabs = styled((props) => (
		<Tabs
			{...props}
			TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
		/>
	))({
		height: 50,
		'& .MuiTabs-flexContainer': {
			justifyContent: 'center',
		},
		'& .MuiTabs-indicator': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent',
		},
		'& .MuiTabs-indicatorSpan': {
			// maxWidth: 500,
			width: '100%',
			backgroundColor: 'transparent',
		},
	});
	const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
		({ theme }) => ({
			height: 70,
			maxWidth: '500px',
			width: '100%',
			textTransform: 'none',
			fontWeight: theme.typography.fontWeightRegular,
			fontSize: theme.typography.pxToRem(22),
			marginRight: theme.spacing(0.2),
			color: '#3D3935',
			backgroundColor: alpha('#FCFCFC', 0.7),
			'&.Mui-selected': {
				color: '#7147BD',
				backgroundColor: alpha('#ffffff', 1),
			},
			':hover': {
				textDecoration: 'none',
			},
		})
	);
	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}>
				{value === index && (
					<Box sx={{ p: 1 }} md={{ p: 0 }}>
						{children}
					</Box>
				)}
			</div>
		);
	}

	function getIcon(title){
		var iconPath = '/images/icon-science-narcolepsy.svg'

		if (title === "XYWAV for Adults With Idiopathic Hypersomnia (IH)") {
			var iconPath = '/images/icon-group-people.svg'

		} else if (title === "Getting Started on XYWAV") {
			var iconPath = '/images/icon-bottle.svg'

		} else{
			var iconPath = '/images/icon-speech-bubble.svg'
		}

		return iconPath
	}

	const [value, setValue] = useState(0);
	const [error, setError] = useState(false);
	const [webinarData, setWebinarData] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const userSelectedWebinars = (selected, UUID, Topic) => {
		let session = selectedWebinars;
		let totalSelected = 0;



		if(selected) {
			webinarData.forEach((item) => {
				if(item.Topic === Topic) {
					item.Sessions.forEach((sessionItem) => {
						if(UUID === sessionItem.UUID) {
							let tempData = {};
							tempData.UUID = UUID;
							tempData.Topic = item.Topic;
							tempData.ShortDescription = item.ShortDescription;

							item.Sessions.forEach((sessionItem) => {
								if(sessionItem.UUID === UUID) {
									tempData.Date = sessionItem.Date;
								}
							});
							session[Topic] = tempData;;
						}
					})
				}
			});
		}
		else {
			session[Topic]="";
			session = Object.fromEntries(Object.entries(session).filter(item => item[0] !== Topic));
		}

		Object.entries(selectedWebinars).map((item, key) => {
			if(item[1]) totalSelected+=1;
		})

		if(totalSelected >= 2) {
			setIsDisabled(true);
		}
		else {
			setIsDisabled(false);
		}

		setSelectedWebinars(session);
	};

	function onRegisterClick(event) {
		let isValid = false;

		if(!webinarData) {
			return;
		}

		if(selectedWebinars) {
			webinarData.forEach((item) => {
				if(selectedWebinars[item.Topic]) {
					isValid = true;
				}
			});
		}

		if(isValid) {
			navigate("register")
		}
		else {
			setError(true);
			const errorLabel = document.querySelector('.error-label');
			errorLabel.focus();
		}
	}

	useEffect(() => {
		if (location.state && location.state.onDemandTabSelected) {
			setValue(1);
		}
		window.addEventListener("scroll", SlowReveal);

		const success = response => {
			setWebinarData(response);
		}
		const fail = error => {
			console.log('Internal Error ', error);
		}
		Api.get_webinar(success, fail, GROUP, CONDITION);
	}, []);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)

		if(urlParams.get("tab") === "ondemand") {
			setValue(1)
		}
	}, [])

	return (
		<>
			<Layout pageType='ih-branded' className='idiopathicHypersomnia' pixelCode='PageView'>
				<Seo
					title='Idiopathic Hypersomnia Treatment Webinars | Jazz Webinars'
					description="Learn about idiopathic hypersomnia (IH), including management advice and treatment options. Jazz Webinars brings you the latest info through live, interactive webinars and on-demand videos delivered to you by experts in sleep medicine."
				/>
				<div>
					{/* <StaticImage
						className='test-desktop'
						src='../../../static/images/ih-branded-hero.png'
						alt='Idiopathic hypersomnia Hero Banner Desktop on homepage page'
					/> */}
					<img
						className='test-desktop'
						src='/images/BRANDED_IH_photoshop_compression_medium.jpg'
						alt='Idiopathic hypersomnia Hero Banner Desktop on homepage page'
					/>
					<div className='ih-branded-hero-mobile'></div>
					<section className='container firstSection'>
						<Grid container>
							<Grid item xs={12}>
								<div className='lv-copy fade-in-move-up'>
									<h1>
										Learn about XYWAV from{' '}
										<br className='mobileOnly' />
										experts in sleep medicine{' '}
										<br className='mobileOnly' />
									</h1>
								</div>
							</Grid>
						</Grid>

						<div className='styledTabs'>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<StyledTabs
											className='tabButtons'
											value={value}
											onChange={handleChange}
											aria-label='styled tabs example'>
											<StyledTab label='Live Webinars' />
											<StyledTab label='Webinars On Demand' />
										</StyledTabs>
									</Grid>
								</Grid>
							</Box>
						</div>
					</section>
				</div>

				<section className='secondSection pageContent'>
					<TabPanel value={value} index={0}>
						<div className='sessionHeader'>
							<div className='container center'>
								<h2>
									Find out how XYWAV can support you through your
									Idiopathic Hypersomnia treatment journey
								</h2>
								<div className='gradient-divider center'>
									<img alt='' src='/images/gradient-divider-ih.svg' />
								</div>
								<p>
									Check out our interactive live webinars, hosted by
									experts in the field of IH, where you can get
									up-to-date information and ask questions about XYWAV.
									If you missed a live webinar, check back for new dates
									and times or watch a previous webinar on demand!
								</p>
							</div>

							<div className='grey-bg'>
								<div className='smaller-container footnote'>
									<p>
										XYWAV<sup>&reg;</sup> (calcium, magnesium,
										potassium, and sodium oxybates) oral solution, 0.5
										g/mL total salts (equivalent to 0.413 g/mL of
										oxybate) is a prescription medicine used to treat:
									</p>
									<ul>
										<li>
											the following symptoms in people 7 years of
											age or older with narcolepsy:
											<ul>
												<li>
													sudden onset of weak or paralyzed
													muscles (cataplexy)
												</li>
												<li>
													excessive daytime sleepiness (EDS)
												</li>
											</ul>
										</li>
										<li>Idiopathic Hypersomnia (IH) in adults</li>
									</ul>
								</div>
							</div>
							<div className='container'>
								{webinarData && webinarData.length ?
									<h3>
										Select up to 2 webinars that you'd like to attend:
									</h3>
								: null }
								{webinarData && webinarData.length ? 
									<Grid container>
										<Grid item xs={12} md={1}>
											{/* <p className='filter-heading'>Filters: </p> */}
										</Grid>
										<Grid item xs={12} md={8}>
											<div className='filters'>
												{/* <Filters
													className='narcolepsy-branded-filters brandedGlobal'
													filters={[
														'Topic Choice',
														'Topic Choice',
														'Topic Choice',
														'Topic Choice',
													]}
												/> */}
											</div>
										</Grid>
										<Grid item xs={12} md={3}>
											<CtaButton
												title='Register Now'
												class='cta link-cta registerBtn btn-transition-branded-global topRegisterBtn'
												click={onRegisterClick}
											/>
										</Grid>
									</Grid>
								: null }

								<p className={!error ? "error-label hidden" : "error-label"} tabIndex={-1}><strong>To proceed, select at least one of the live webinar sessions available.</strong></p>
							</div>
						</div>
						<div className='sessionInfo container'>
							{/* reveal */}
							<div className='SessionInfoCard'>
								{webinarData ? webinarData.map((item, key) => (
									<SessionInfoCard
										id={key}
										group={item.Topic}
										header={item.Topic}
										leftCopy={item.ShortDescription || 'No description provided'}
										radioLabelCopy='Next Available Sessions'
										name={item.Name}
										selected={Object.entries(selectedWebinars).length && selectedWebinars[item.Topic] ? selectedWebinars[item.Topic].UUID : null}
										radioItems={item.Sessions}
										iconObj={{
											image:  getIcon(item.Topic),
											width: '120',
											height: '120',
											alt: 'Icon',
											placeholder: '',
										}}
										disabled = {isDisabled && !selectedWebinars[item.Topic] ? true : false}
										functionCall={userSelectedWebinars}
									/>
								)) : <Preloader/> }
								{webinarData != null && webinarData.length === 0 ?
									<div className='comingSoonBorder centerText'>
										<p className='comingSoonHeader'>
											<strong>Coming Soon!</strong>
										</p>
										<p>
										Check back at a later date for live webinars.
										</p>
									</div>
								: null}
							</div>
							{webinarData && webinarData.length ?
								<CtaButton
									title='Register Now'
									class='cta link-cta registerBtn btn-transition-branded-global'
									click={onRegisterClick}
								/>
							: null }
							<div className='whiteSpace'></div>
						</div>
					</TabPanel>
					<div className='container'>
						<TabPanel value={value} index={1}>
							<div className='sessionHeader'>
								<h3 className='no-record-padding'>
									Didn't get a chance to catch the live webinar? No
									worries! You can find <br className='desktopOnly' />
									videos of all the previous webinars right here—on
									demand.
								</h3>
								<Grid container>
									<Grid item xs={12} md={1}>
										{/* <p className='filter-heading'>Filters: </p> */}
									</Grid>
									<Grid item xs={12} md={11}>
										<div className='filters'>
											{/* <Filters
												className='narcolepsy-branded-filters brandedGlobal'
												filters={[
													'Topic Choice',
													'Topic Choice',
													'Topic Choice',
													'Topic Choice',
												]}
											/> */}
										</div>
									</Grid>
								</Grid>
								{/* <div className='gradient-divider center'>
									<img alt='' src='/images/gradient-divider-ih.svg' />
								</div> */}
								{/* <Grid container>
									<Grid item xs={12} className='center comingSoonLabel'>
										<div className='comingSoonBorder'>
											<p className='comingSoonHeader'>
												<strong>Coming Soon!</strong>
											</p>
											<p>
												Check back at a later date for webinars on
												demand.
											</p>
										</div>
									</Grid>
								</Grid> */}
							</div>
							<Grid container>
								<Grid item xs={12}>
									<Grid container spacing={6} direction='row' className='tabletCenter'>
										{data.map((videoItem, index) => (
										<OnDemandVideo
											index={index}
											linkTo='/xywav-idiopathic-hypersomnia/on-demand-video'
											// poster="/images/video1.png"
											title={videoItem.videoTitle}
											// speaker={videoItem.speakerName + ', ' videoItem.speakerTitle}
											desc={videoItem.videoDescription}
											thumbnail={videoItem.thumbnail}
											videoId={videoItem.videoId}
											videoTitle={videoItem.videoTitle}
											videoResource={videoItem.videoResource}
									/>
									))}
									</Grid>
								</Grid>
							</Grid>
						</TabPanel>
					</div>
				</section>
			</Layout>
		</>
	);
};

export default IhbrandedHomePage;
