export const data = [
    {
        "position": 1,
        "videoId": "ongfqlpbkh",
        "videoTitle": "Getting Started on XYWAV",
        "videoDescription": "Wondering what to expect when starting XYWAV? Learn about the basics of XYWAV, how to get it, as well as support programs and resources that are available to you. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/382f73ea55d42f0f890a6f9079306954.webp",
        "speakerName": "Logan Schneider, MD",
        "speakerTitle": "Clinical Assistant Professor of Sleep Medicine at Stanford University Medical Center",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Looking to get more support for XYWAV?",
                "resourceDescription": "JazzCares® offers you access to information, resources, and programs that can help support you while you are taking XYWAV.",
                "resourceLinkName": "Learn about JazzCares",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            },
            {
                "resourceTitle": "Looking for useful resources about XYWAV and IH?",
                "resourceDescription": "We have a range of helpful resources including the XYWAV IH Patient Brochure, the Epworth Sleepiness Scale, and the Idiopathic Hypersomnia Severity Scale to help you navigate your treatment journey with IH.",
                "resourceLinkName": "EXPLORE OUR RESOURCES",
                "resourceLink": "https://www.xywav.com/idiopathic-hypersomnia/patient-resources/"
            },
            {
                "resourceTitle": "How to get XYWAV",
                "resourceDescription": "Find out how you can get XYWAV through a Certified Pharmacy with the XYWAV and XYREM REMS.",
                "resourceLinkName": "GETTING XYWAV",
                "resourceLink": "https://www.xywav.com/getting-xywav/"
            }
        ]
    },
    {
        "position": 2,
        "videoId": "2ap1vwz65w",
        "videoTitle": "XYWAV for Adults with Idiopathic Hypersomnia",
        "videoDescription": "Hear from experts as they highlight important information about XYWAV, the first and only  FDA-approved treatment for adults living with idiopathic hypersomnia (IH). Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/5c6820d4dbc11e6e78b1fb7bd9fd359e.webp",
        "speakerName": "Edward Mezerhane, MD, FAASM, FACP",
        "speakerTitle": "Facility Director and Principal Investigator at Sleep Medicine Specialists of South Florida",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Looking for useful resources about XYWAV and IH?",
                "resourceDescription": "We have a range of helpful resources including the XYWAV IH Patient Brochure, the Epworth Sleepiness Scale, and the Idiopathic Hypersomnia Severity Scale to help you navigate your treatment journey with IH.",
                "resourceLinkName": "UNDERSTAND YOUR IH",
                "resourceLink": "https://www.xywav.com/idiopathic-hypersomnia/patient-resources/"
            },
            {
                "resourceTitle": "Why consider XYWAV for IH?",
                "resourceDescription": "Take a closer look at the clinical trial results for XYWAV in IH patients.",
                "resourceLinkName": "VIEW STUDY RESULTS",
                "resourceLink": "https://www.xywav.com/idiopathic-hypersomnia/xywav-efficacy/"
            },
            {
                "resourceTitle": "Looking to get more support for XYWAV?",
                "resourceDescription": "JazzCares® offers you access to information, resources, and programs that can help support you while you are taking XYWAV.",
                "resourceLinkName": "Learn about JazzCares",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            }
        ]
    },
    {
        "position": 3,
        "videoId": "6k4elhzr7u",
        "videoTitle": "Talking to Your Doctor About Idiopathic Hypersomnia and XYWAV",
        "videoDescription": "Make the most out of your next appointment with your doctor. We’ll discuss key symptoms to help start a conversation, questions to ask your doctor, and why you may want to consider XYWAV. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/697235d443cb49c37d64a703c2f0175a.webp",
        "speakerName": "Logan Schneider, MD",
        "speakerTitle": "Clinical Assistant Professor of Sleep Medicine at Stanford University Medical Center",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Make the Most Out of Your Next Doctor's Visit",
                "resourceDescription": "Our Doctor Visit Checklist can help you log your symptoms and start a conversation with your doctor to see if XYWAV could be right for you.",
                "resourceLinkName": "DOWNLOAD THE CHECKLIST",
                "resourceLink": "https://www.xywav.com/idiopathic-hypersomnia/talking-to-your-doctor/"
            },
            {
                "resourceTitle": "Why consider XYWAV for IH?",
                "resourceDescription": "Learn more about the first and only  FDA-approved treatment for adults living with IH.",
                "resourceLinkName": "LEARN ABOUT XYWAV",
                "resourceLink": "https://www.xywav.com/idiopathic-hypersomnia/what-is-xywav/"
            },
            {
                "resourceTitle": "Looking to get more support for XYWAV?",
                "resourceDescription": "JazzCares® offers you access to information, resources, and programs that can help support you while you are taking XYWAV.",
                "resourceLinkName": "Learn about JazzCares",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            }
        ]
    }
]